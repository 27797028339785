<template>
  <div class="page">
    <list-layout
      ref="consultServiceReplyRef"
      :filter-form="form"
      :thead="thead"
      :on-fetch="getList"
      :tab-arr="tabArr"
    >
      <template #filter>
        <consult-service-reply-filter
          :filter-form="form"
          :upload-filter="ok"
        ></consult-service-reply-filter>
      </template>
      <template v-slot:operation="scope">
        <el-button
          size="small"
          :loading="downloadingId === scope.row.chat_id"
          @click="exportMsgs(scope.row)"
          type="text"
        >
          导出记录</el-button
        >
        <el-button size="small" @click="viewChatHistory(scope.row)" type="text"
          >查看聊天记录</el-button
        >
        <el-button size="small" @click="viewUserInfo(scope.row)" type="text"
          >查看用户信息</el-button
        >
        <el-button
          v-if="form.tab === 'question'"
          size="small"
          @click="viewSurvey(scope.row)"
          type="text"
          >查看问卷表单</el-button
        >
      </template>
    </list-layout>
    <MessageListDialog
      v-model="messageListDialog.show"
      :chat_id="messageListDialog.chat_id"
      :user_id="messageListDialog.user_id"
      :leftAvatar="messageListDialog.leftAvatar"
    ></MessageListDialog>
    <UserInfoDrawer
      v-model="userInfoDrawer.show"
      :chat_id="userInfoDrawer.chat_id"
      :user_id="userInfoDrawer.user_id"
      :leftAvatar="messageListDialog.leftAvatar"
    >
    </UserInfoDrawer>
    <SurveyListDialog
      :chat_id="surveyDialog.chat_id"
      :user_id="surveyDialog.user_id"
      v-model="surveyDialog.show"
    />
  </div>
</template>

<script>
import ConsultServiceReplyFilter from '@/modules/consult/components/consult-service/consult-list/consult-list-filter'
import {
  getConsultList,
  exportMsgs,
} from '@/modules/consult/api/consult-service/consult-list'
import ListLayout from '@/base/layout/ListLayout'
import MessageListDialog from '@/modules/consult/components/consult-service/consult-list/MessageListDialog.vue'
import UserInfoDrawer from '@/modules/consult/components/consult-service/consult-list/UserInfoDrawer.vue'
import SurveyListDialog from '@/modules/consult/components/consult-service-workbench/SurveyListDialog.vue'

export default {
  components: {
    ListLayout,
    ConsultServiceReplyFilter,
    MessageListDialog,
    UserInfoDrawer,
    SurveyListDialog,
  },
  data() {
    return {
      tabArr: [
        { label: '自动回复', name: 'auto' },
        { label: '人工服务', name: 'question' },
      ],
      form: {
        create_start_time: -1,
        create_end_time: -1,
        category_name: '',
        question_status: -1,
        status: -1,
        user: '',
        admin_user: '',
        page: 1,
        page_size: 20,
        tab: 'auto',
      },
      // 弹窗不需要显示操作列，需要重新声明覆盖掉
      thead: [
        {
          label: '客户头像',
          type: 'image',
          prop: 'user_avatar',
          minWidth: 100,
        },
        { label: '客户名称', prop: 'user_nickname', minWidth: 100 },
        {
          label: '询问类型',
          prop: 'category_name',
          minWidth: 100,
          visible: (r, q) => q.tab === 'question',
        },
        {
          label: '对接客服',
          prop: 'admin_user_nickname',
          minWidth: 100,
          visible: (r, q) => q.tab === 'question',
        },
        {
          label: '解决状态',
          prop: 'question_status_text',
          minWidth: 100,
          visible: (r, q) => q.tab === 'question',
        },
        {
          label: '询问状态',
          prop: 'status_text',
          minWidth: 100,
          visible: (r, q) => q.tab === 'question',
        },
        {
          label: '首次询问时间',
          prop: 'create_time',
          minWidth: 180,
          visible: (r, q) => q.tab === 'question',
        },
        {
          label: '操作',
          type: 'operation',
          width: 420,
          operations: [],
        },
      ],
      messageListDialog: {
        show: false,
        chat_id: '',
        user_id: '',
        leftAvatar: '',
      },
      userInfoDrawer: {
        show: false,
        chat_id: '',
        user_id: '',
      },
      //问卷信息
      surveyDialog: {
        show: false,
        user_id: '',
        chat_id: '',
      },
      downloadingId: '',
    }
  },
  methods: {
    // 筛选回调
    ok(e) {
      this.onRefresh()
    },
    onRefresh() {
      this.$refs.consultServiceReplyRef?.getList({ page: 1 })
    },
    // 拉取数据
    getList(query) {
      return getConsultList(query)
    },
    viewChatHistory(row) {
      this.messageListDialog.show = true
      this.messageListDialog.chat_id = row.chat_id
      this.messageListDialog.user_id = row.user_id
      this.messageListDialog.leftAvatar = row.user_avatar
    },
    viewUserInfo(row) {
      this.userInfoDrawer.show = true
      this.userInfoDrawer.chat_id = row.chat_id
      this.userInfoDrawer.user_id = row.user_id
    },
    viewSurvey(row) {
      this.surveyDialog.show = true
      this.surveyDialog.chat_id = row.chat_id
      this.surveyDialog.user_id = row.user_id
    },
    exportMsgs(row) {
      this.downloadingId = row.chat_id
      exportMsgs({
        chat_id: row.chat_id,
        user_id: row.user_id,
      })
        .then((res) => {
          this.downloadingId = ''
        })
        .catch(() => {
          this.downloadingId = ''
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
