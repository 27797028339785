import api from "@/base/utils/request";
import fetchFile from '@/base/utils/download'
// 获取分类列表
export const getConsultList = data => {
  return api({
    url: "/admin/consult/workbench/chats",
    method: "post",
    data
  });
};

export const getServiceCategory = data => {
  return api({
    url: "/admin/consult/serviceCategory/all",
    method: "post",
    data
  });
};
export const exportMsgs = data => {
  return fetchFile({
    url: "/admin/consult/workbench/exportMsgs",
    method: "post",
    data,
    download: true
  });
};
