<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
    >
      <div class="filter-item">
        <el-form-item label="客户名称：" prop="keyword">
          <el-input v-model="form.user" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item v-if="form.tab === 'question'" label="询问类型：" prop="category_name">
          <el-select v-model="form.category_name" placeholder="请选择询问类型 ">
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.tab === 'question'" label="对接客服：" prop="admin_user">
          <el-input v-model="form.admin_user" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div v-if="form.tab === 'question'" class="filter-item">
        <el-form-item label="解决状态：" prop="question_status">
          <el-select
            v-model="form.question_status"
            placeholder="请选择解决状态 "
          >
            <el-option
              v-for="item in questionStatusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="询问状态：" prop="status">
          <el-select
            v-model="form.status"
            placeholder="请选择解决状态 "
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div v-if="form.tab === 'question'" class="filter-item">
        <DatePeriodPicker
          label="创建时间："
          start-prop="create_start_time"
          :start-time.sync="form.create_start_time"
          end-prop="create_end_time"
          :end-time.sync="form.create_end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { getServiceCategory } from '../../../api/consult-service/consult-list'
export default {
  name: 'consult-service-reply-filter',
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      categoryList: [],
      questionStatusList: [
        {
          id: -1,
          name: '全部',
        },
        {
          id: 1,
          name: '已解决',
        },
        {
          id: 3,
          name: '未解决',
        },
      ],
      statusList:[
        {
          id: -1,
          name: '全部',
        },
        {
          id: 2,
          name: '待沟通',
        },
        {
          id: 3,
          name: '沟通中',
        }, {
          id: 4,
          name: '已结束',
        }     
      ]
    }
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
    getServiceCategory() {
      getServiceCategory().then((res) => {
        this.categoryList = res.data
      })
    },
    // 导出
    // onExport() {
    //   const postData = { ...this.form, page: this.page };
    //   return topicsExport(postData)
    //     .then()
    //     .catch(() => {});
    // },
  },
  created() {
    this.getServiceCategory()
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
