<template>
  <el-drawer
    title="用户信息"
    :visible.sync="show"
    direction="rtl"
    append-to-body
  >
    <UserInfo :chat_id="chat_id" :user_id="user_id" :leftAvatar="leftAvatar" />
  </el-drawer>
</template>

<script>
import UserInfo from '@/modules/consult/components/consult-service-workbench/UserInfo/index.vue'
export default {
  components: {
    UserInfo,
  },
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    // 是否打开
    open: {
      type: Boolean,
      required: true,
    },
    chat_id: String,
    user_id: String,
    leftAvatar:String,
  },
  watch: {
    show(val) {
      if (val) {
      }
    },
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('handleOpen', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.radio {
  margin-bottom: 20px;
}
.remarks {
  height: 50vh;
  overflow: auto;
  padding: 0 40px;
}
</style>
